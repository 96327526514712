import { TipModeEnum } from '@/types';
import { IPGExchange } from '@clubpay/customer-common-module/src/repository/common/payment/type';
import { IForex } from '@clubpay/payment-kit';

export enum CurrentStatuses {
  CREATED = 'created',
  DISCARDED = 'discarded',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  EXPIRED = 'expired',
}

export type CalculateTipProps = {
  remaining: string | undefined;
  selectedTip: number | undefined;
  tipMode: TipModeEnum;
  customTip: string;
  isTipEnabled?: boolean;
};

export type calculateTotalAmountsProps = CalculateTipProps & {
  currencyPrecision: number | undefined;
  forex: IForex | undefined;
};

export type UserInfo = {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  dsi: string;
  postPaymentActionsDone?: boolean;
  receiptSent?: boolean;
};

export type usePostPaymentActionsProps = {
  ref?: string;
  enabled?: boolean;
};
