import { memo, useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'next-export-i18n';

import { useConfigContext, usePaymentLink, useRestaurant } from '@/hooks';
import { useStore } from '@/store';
import { TipModeEnum } from '@/types';
import { calculateTotalAmounts } from '@/utils';
import { getLocaleText } from '@clubpay/customer-common-module/src/component/MultiLocale';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import {
  ForexCurrencyChangeV2,
  setForex,
  useForexStore,
} from '@clubpay/payment-kit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Collapse, Container, Stack, Typography } from '@mui/material';
import { Typography as TypographyQlub } from '@qlub-dev/qlub-kit';

import ChangeCurrency from '../drawers/change-currency';
import styles from './styles.module.scss';

const YouPay = memo(function YouPay() {
  const { t } = useTranslation();
  const { lang } = useQlubRouter();
  const { data } = usePaymentLink();
  const { data: restaurant } = useRestaurant();
  const { config } = useConfigContext();
  const selectedTip = useStore(state => state.selectedTip);
  const customTip = useStore(state => state.customTip);
  const forex = useForexStore();

  const [forexV1Open, setForexV1Open] = useState(false);

  const forexUI = restaurant?.config.forexUI ?? 'v2';

  const changeCurrencyHandler = (currency: string | null) => {
    if (currency === forex?.currencyCode) setForex({ isApplied: true });
    else if (forex.isInterrupted) setForex({ isApplied: false });
  };

  const getAmounts = useCallback(() => {
    let { total, tip } = calculateTotalAmounts({
      remaining: data?.bill.remaining,
      selectedTip,
      customTip,
      tipMode: restaurant?.config.paymentLinkTipMode ?? TipModeEnum.Default,
      currencyPrecision: data?.currencyPrecision,
      isTipEnabled: restaurant?.config.paymentLinkTipEnabled,
    });
    let commission = Number(data?.bill.remainingCommission).toFixed(
      data?.currencyPrecision ?? 2,
    );

    // original values before forex exchange
    const preExchange = {
      total,
      tip,
      commission,
    };

    if (forex?.isApplied) {
      total = (Number(total) * forex.exchangeRate).toFixed(
        data?.currencyPrecision ?? 2,
      );
      tip = (Number(tip) * forex.exchangeRate).toFixed(
        data?.currencyPrecision ?? 2,
      );
      commission = (Number(commission) * forex.exchangeRate).toFixed(
        data?.currencyPrecision ?? 2,
      );
    }

    return { total, tip, commission, preExchange };
  }, [
    data?.bill.remaining,
    selectedTip,
    customTip,
    restaurant?.config.paymentLinkTipMode,
    data?.currencyPrecision,
    restaurant?.config.paymentLinkTipEnabled,
    forex,
  ]);

  const currency = useMemo(() => {
    if (forex?.isApplied) {
      return (forex.currencyCode ?? '').toUpperCase();
    }
    return data?.currency.toUpperCase();
  }, [forex?.isApplied, forex?.currencyCode, data?.currency]);

  const getCommissionLabel = useCallback(
    () =>
      getLocaleText(
        config?.paymentLinkCustomerCommissionAlias ?? '',
        lang,
        'en',
      ),
    [lang],
  );

  return (
    <Container className={styles.yourPaymentContainer}>
      <Stack gap='8px'>
        {Number(getAmounts().commission) > 0 && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            gap={2}
          >
            <Typography
              variant='body_md'
              color='var(--onSurfaceColors-mediumEmphasis)'
            >
              {getCommissionLabel().trim() === ''
                ? t('customer-commission')
                : getCommissionLabel()}
            </Typography>
            <Stack direction='row' gap='4px' alignItems='baseline'>
              <Typography variant='currency'>{currency}</Typography>
              <Typography
                variant='body_md'
                color='var(--onSurfaceColors-mediumEmphasis)'
              >
                {getAmounts().commission}
              </Typography>
            </Stack>
          </Stack>
        )}
        {forex.isInterrupted && forexUI === 'v1' && (
          <ChangeCurrency
            exchange={forex}
            vendorCurrency={restaurant?.country.currencyCode || ''}
            currencyPrecision={data?.currencyPrecision || 0}
            onChange={changeCurrencyHandler}
            open={forexV1Open}
            onOpen={() => {}}
            total={Number(getAmounts().preExchange.total)}
            onClose={() => {
              setForexV1Open(false);
            }}
          />
        )}
        {forex.isInterrupted && forexUI === 'v2' && <ForexCurrencyChangeV2 />}
        <Collapse in={Number(getAmounts().tip) > 0}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'
            gap={2}
          >
            <Typography
              variant='body_md'
              color='var(--onSurfaceColors-mediumEmphasis)'
            >
              {t('tip')}
            </Typography>
            <Stack direction='row' gap='4px' alignItems='baseline'>
              <Typography variant='currency'>{currency}</Typography>
              <Typography
                variant='body_md'
                color='var(--onSurfaceColors-mediumEmphasis)'
              >
                {getAmounts().tip}
              </Typography>
            </Stack>
          </Stack>
        </Collapse>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='baseline'
          gap={2}
        >
          <Typography variant='title_md'>{t('you-pay')}</Typography>
          <Stack direction='row' gap='4px' alignItems='baseline'>
            <Typography variant='currency'>{currency}</Typography>
            <Typography variant='headline_sm'>{getAmounts().total}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box className={styles.currencyChangeContainer}>
        <Typography variant='caption' color='var(--onSurfaceColors-disabled)'>
          {t('inclusive-of-all-taxes-and-charges')}
        </Typography>
        {forex.isInterrupted && forexUI === 'v1' && (
          <Box className={styles.changeButtonContainer}>
            <TypographyQlub
              onClick={() => setForexV1Open(true)}
              typo='caption_overline'
              className={styles.changeButton}
            >
              {t('Change currency')}
              <ChevronRightIcon />
            </TypographyQlub>
          </Box>
        )}
      </Box>
    </Container>
  );
});

export default YouPay;
