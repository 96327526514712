import { memo, useMemo } from 'react';

import { useCheckLinkUpdates, usePaymentLink } from '@/hooks';
import { useStore } from '@/src/store';
import { Container, Divider, Typography } from '@mui/material';

import styles from './styles.module.scss';

const LinkDetails = memo(function LinkDetails() {
  const { data } = usePaymentLink({ shouldUpdateStatus: true });
  const exchange = useStore(state => state.exchange);

  useCheckLinkUpdates();

  const billAmount = useMemo(() => {
    let amount = Number(data?.bill.billAmount || 0);
    if (exchange?.isApplied) {
      amount = amount * exchange.ratio;
    }
    return amount.toFixed(data?.currencyPrecision || 2);
  }, [
    exchange?.isApplied,
    exchange?.ratio,
    data?.bill.billAmount,
    data?.currencyPrecision,
  ]);

  const currency = useMemo(() => {
    if (exchange?.isApplied) {
      return exchange.currencyCode.toUpperCase();
    }
    return data?.currency.toUpperCase();
  }, [exchange?.isApplied, exchange?.currencyCode, data?.currency]);

  return (
    <>
      <Container className={styles.linkDetailsContainer}>
        <Typography variant='body_sm'>{data?.meta?.reference}</Typography>
        <Typography display='flex' gap='6px' variant='title_lg'>
          {currency}
          <Typography variant='title_lg'>{billAmount}</Typography>
        </Typography>
      </Container>
      {data?.meta?.description && (
        <>
          <Container className={styles.descriptionContainer}>
            <Typography
              variant='caption'
              color='var(--onSurfaceColors-highEmphasis)'
              whiteSpace='pre-wrap'
            >
              {data?.meta?.description}
            </Typography>
          </Container>
          <Divider />
        </>
      )}
    </>
  );
});

export default LinkDetails;
